import React from "react"
import { withTranslation } from "react-i18next"
import "../../css/Footer.css"
import PrivacyPolicy from "../privacy/PrivacyPolicy"
import { Link } from "react-router-dom"
/** @jsx jsx */
import { jsx, css } from "@emotion/react"
import styled from "@emotion/styled"

const EWrapper = styled.div`
`

const Footer = (props) => {
  const [modalShow, setModalShow] = React.useState(false)
  const { t, i18n, customClass, fromAssessment, fromSummary, fromPap, fromEducation, fromGoal } = props;
  return (
    <footer>
      <EWrapper className="footer-bl">
        <div
          // className={customClass || "container"}
          className={`container ${fromAssessment == true ? "assessment-conatiner" : ''} ${fromSummary == true ? "summary-container" : ''} ${fromPap == true ? 'pap-container' : ''} ${fromEducation == true ? 'education-container' : ''} ${fromGoal == true ? 'goal-page-footer' : ''}`}
        >
          <div className="footer-block">
            <div className="block">
              <ul className="footerUl">
                <li className="ftr-logo">
                  <img
                    src={require("../../assets/images/FeelingBetterNowLogo.svg").default}
                    style={{ height: 40 }}
                    alt=""
                    className="align-item-center"
                  />
                </li>
                <div>
                  <ul className="social-icon">
                    <li className="facebook">
                      <a href="https://www.facebook.com/Feeling-Better-Now-104118241297424/" target="_blank">
                        facebook
                      </a>
                    </li>
                    <li className="twitter">
                      <a href="https://twitter.com/mentalhealthfbn?lang=en" target="_blank">
                        twitter
                      </a>
                    </li>
                    <li className="linkedin">
                      <a href="https://www.linkedin.com/company/feelingbetternow" target="_blank">
                        linkedin
                      </a>
                    </li>
                    <li className="instagram">
                      <a href="https://www.instagram.com/instafeelingbetternow/" target="_blank">
                        instagram
                      </a>
                    </li>
                  </ul>
                  <div className="copyright">
                    {t("footerSection.copy")}
                  </div>
                </div>
              </ul>
            </div>

            <div
              className="block"
            >
              <ul className="footerUl" style={{ listStyleType: "none" }}>
                <h2>{t("footerSection.contactInfo")}</h2>
                <ul className="list-items address-dtl">
                  <li className="phone-number adjust-1">
                    <img src={require("../../assets/images/icon-phone.png")} alt="" />
                    <a href="tel:416 928 9195">
                      <span>{t("footerSection.contactNo")} </span>
                    </a>
                  </li>
                  <li className="phone-number">
                    <img src={require("../../assets/images/icon-email.png")} alt="" />
                    <a href="mailto:info@feelingbetternow.com">
                      <span>info@feelingbetternow.com</span>
                    </a>
                  </li>
                </ul>

                {/* <PrivacyPolicy
                show={modalShow}
                onHide={() => setModalShow(false)}
              /> */}

                {/* <li>Terms of Use</li> */}
                {/* <li>Sales and Refunds</li>
            <li>Site Map</li> */}
              </ul>
            </div>

            <div
              className="block"
            >
              <ul className="footerUl" style={{ listStyleType: "none" }}>
                <h2>{t("footerSection.account")}</h2>
                <ul className="list-items">
                  {/* <li>
                    <a href="https://mensanteid.b2clogin.com/bd2bbd66-bd80-417e-9a3d-bbf975f4d919/oauth2/v2.0/authorize?p=B2C_1_reset_password_flow&client_id=ffbaada4-c315-47b7-b500-e1b96eccaaaa&nonce=defaultNonce&redirect_uri=https%3A%2F%2Ffbn3staging.ca&scope=openid&response_type=id_token&prompt=login">{t("footerSection.forgotUser")}</a>
                  </li> */}
                  <li>
                    <a href="https://mensanteid.b2clogin.com/bd2bbd66-bd80-417e-9a3d-bbf975f4d919/oauth2/v2.0/authorize?p=B2C_1_reset_password_flow&client_id=ffbaada4-c315-47b7-b500-e1b96eccaaaa&nonce=defaultNonce&redirect_uri=https%3A%2F%2Ffbn3staging.ca&scope=openid&response_type=id_token&prompt=login">{t("footerSection.forgotPassword")}</a>
                  </li>
                  <li>
                    <a href="mailto:help@mensante.com">{t("footerSection.help")}</a>
                  </li>
                </ul>

                {/* <PrivacyPolicy
                show={modalShow}
                onHide={() => setModalShow(false)}
              /> */}

                {/* <li>Terms of Use</li> */}
                {/* <li>Sales and Refunds</li>
            <li>Site Map</li> */}
              </ul>
            </div>
            <div
              className="block"
            >
              <h2>{t("footerSection.legal")}</h2>
              <ul className="footerUl">
                <ul className="list-items">
                  <li>
                    <Link to="/privacypolicy">{t("footerSection.policy")}</Link>
                  </li>
                  <li>
                    <Link to="/termcondition">{t("footerSection.terms")}</Link>
                  </li>
                  <li>
                    <a href="https://fbn3staging.ca/sitemap.xml" target="_blank">{t("footerSection.siteMap")}</a>
                  </li>
                </ul>
                {/* <li>
              <h4>Updates</h4>
            </li>
            <br />
            <li>Morem ipsum dolor sit met.</li>
            <li className="fMute">Psum dolor sit amet...</li>
            <hr />
            <li>Morem ipsum dolor sit met.</li>
            <li className="fMute">Psum dolor sit amet...</li> */}
              </ul>
            </div>
          </div>
        </div>
      </EWrapper>
    </footer>
  )
}

export default withTranslation()(Footer)
