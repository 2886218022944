import React, { useState, useEffect } from "react"
import { withTranslation } from "react-i18next"
import { baseUrl } from "../../lib/constants"
import "../../css/EducationModule.scss"
// import Navbar from "../../components/navbar/Navbar"
import Navbar from "../../components/navbar/EducationHeader"
import "../../css/main.css"
import Footer from "../../components/footer/Footer"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import EducationCardList from "./EducationCardList"
import "../../css/education.scss"
import { usePrevious } from "react-hookedup"
import { disorderNames } from "../../data"
import logClickEvent from "../../utils/logClickEvent"
// import FeedbackModel from "../../components/popups/feedback"
var store = require("store")
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: 0,
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
    background: "white",
    borderRadius: 8,
    fontSize: 18,
    fontFamily: "CircularStdBook",
    padding: 10,
    height: "auto",
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  Buttons: {
    padding: "0px !important",
  },
  menuItem: {
    textAlign: "center !important",
  },
}))

var disorders = [
  // "Meridian",
  // "Ryerson",
  "Depression",
  "Mania/Hypomania",
  "Alcohol and Substance Abuse",
  "Panic Disorder",
  "Social Anxiety",
  "PTSD",
  "Obsessive Compulsive Disorder",
  "General Anxiety Disorder",
  "Eating Disorder",
  "ADHD",
  "Anger Problems",
  "Adjustment Disorder",
  "Sleep Disorder (Insomnia)",
  "Covid",
]

const listObject = {
  // "Meridian": "Meridian",
  ADHD: "adhd",
  "Adjustment Disorder": "adjustmentDisorder",
  "Alcohol and Substance Abuse": "alcohol",
  "Anger Problems": "angerProblems",
  "Mania/Hypomania": "bipolarDisorder",
  Depression: "depression",
  "Eating Disorder": "eatingDisorder",
  "General Anxiety Disorder": "gad",
  "Sleep Disorder (Insomnia)": "insomnia",
  "Obsessive Compulsive Disorder": "ocd",
  "Panic Disorder": "panicDisorder",
  PTSD: "ptsd",
  "Social Anxiety": "socialAnxiety",
  "Covid": "covid",
  "Ryerson": "ryerson"
}

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  }
}
// let envInfo = localStorage.getItem('envConfig');
// try {
//   envInfo = JSON.parse(envInfo || '');
// } catch (error) {
//   envInfo = {};
// }
// if (envInfo.resourcesLabel) {
//   disorders = [envInfo.resourcesLabel, ...disorders];
//   listObject[envInfo.resourcesLabel] = envInfo.resourcesKey;
//   // setSelectedDisorder(disorders[0]);
// }

const envName = localStorage.getItem('env') || '';

function SupportResources(props) {

  // setEnv(envName);

  const [filters, setFilters] = useState([])
  const [env, setEnv] = useState(envName);
  const [selectedDisorder, setSelectedDisorder] = useState(disorders[0])
  const { t, i18n } = props;
  const [auth, setAuth] = useState(true)
  const classes = useStyles()
  const theme = useTheme()
  const [personName, setPersonName] = useState([])
  const [resourcesData, setResourcesData] = useState([])
  const [data, setData] = useState([])
  const [bookmarks, setBookmarks] = useState([])
  const [bookmarksIds, setBookmarksIds] = useState([])
  const [renderBookmarks, setRenderBookmarks] = useState(false)
  const [bookmarksData, setBookmarksData] = useState([])
  const [selectFilter, setSelectFilter] = useState('');
  const [bookmarkClickedColour, setBookmarkClickedColour] = useState("#151852")
  const [resources, setResources] = useState([
    "Support Resources",
    // "Video Resources",
    "Websites",
    // "RSS Feeds",
    "Articles",
    "Forum",
    "Support Group",
    // "Games & Apps",
    // "Meridian",
    // "On Campus",
    // "Off Campus"
  ])
  const resourcesTranslations = {
    "Support Resources": {
      en: "Support Resources",
      fr: "Ressources d'assistance"
    },
    "Websites": {
      en: "Websites",
      fr: "Sites Internet"
    },
    "Articles": {
      en: "Articles",
      fr: "Des articles"
    },
    "Forum": {
      en: "Forum",
      fr: "Forum"
    },
    "Support Group": {
      en: "Support Group",
      fr: "Groupe de soutien"
    },
  }
  const filterMapping = {
    academicPapers: 'Articles',
    // rssFeed: 'RSS Feeds',
    supportResources: 'Support Resources',
    videoResources: 'Video Resources',
    websites: 'Websites',
    forum: 'Forum',
    supportGroup: 'Support Group',
    // onCampus: 'On Campus',
    // offCampus: 'Off Campus',
    // gamesApps: 'Games & Apps',
    // meridian: 'Meridian',
  };
  const [clientIdCode, setClientIdCode] = useState(null)
  const [loading, setLoading] = useState(false)
  const [bookmarkLoading, setBookmarkLoading] = useState(false)

  // feedback state and helper
  const [showfeedback, setShowfeedback] = useState(false);

  const handleClosefeedback = () => setShowfeedback(false);
  const handleShowfeedback = () => setShowfeedback(true);

  const configureEnvInDisorders = () => {
    let envInfo = localStorage.getItem('envConfig');
    try {
      envInfo = JSON.parse(envInfo || '')
    } catch (error) {
      envInfo = {};
    }
    if (envInfo.resourcesLabel) {
      disorders = [envInfo.resourcesLabel, ...disorders];
      listObject[envInfo.resourcesLabel] = envInfo.resourcesKey;
      setSelectedDisorder(disorders[0]);
    }

    const envName = localStorage.getItem('env');
    setEnv(envName);
  };

  useEffect(() => {

    setTimeout(() => {
      handleShowfeedback()
    }, 15000)

    // configureEnvInDisorders()

    i18n.changeLanguage(store.get('lang') || 'en')
    if (props.location && props.location.state && props.location.state.from == 'covid-bar') {
      setSelectedDisorder('Covid');
    }
    setLoading(true);
    fetch(baseUrl + `/user-profile`, {
      credentials: "include",
      mode: "cors",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        // setResources([...resources, res.accessId])
        setClientIdCode(res.accessId)
        setBookmarksIds(res.bookmarks)
        // resources.unshift((res.organization && res.organization.organization_name) ? res.organization.organization_name : res.accessId)
        // setResources([...resources])
        setClientIdCode((res.organization && res.organization.organization_name) ? res.organization.organization_name : res.accessId)
        setBookmarksIds(res.bookmarks)
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      })
  }, [])

  const handleChange = (event) => {
    setPersonName(event.target.value)
  }


  const postBookmarkIds = async (arr, fromBookmark = false) => {
    if (fromBookmark) {
      setBookmarkLoading(true)
    } else {
      setLoading(true)
    }
    fetch(baseUrl + `/user-profile/bookmarks`, {
      credentials: "include",
      mode: "cors",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ bookmarks: arr ?? bookmarks, readOnly: true }),
    })
      .then((res) => res.json())
      .then((res) => {
        setBookmarksData(res)
        if (fromBookmark) {
          setBookmarkLoading(false)
        } else {
          setLoading(false)
        }
      }).catch((err) => {
        console.log(err);
        if (fromBookmark) {
          setBookmarkLoading(false)
        } else {
          setLoading(false)
        };
      })
  }

  useEffect(() => {
    setRenderBookmarks(false)
    setLoading(true);
    console.log('api called to get resources for ', listObject[selectedDisorder])
    fetch(baseUrl + `/education/resources`, {
      credentials: "include",
      mode: "cors",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        env,
      },
      body: JSON.stringify({
        disorder: listObject[selectedDisorder],
      }),
    })
      .then((res) => res.json())
      .then((res) => {

        setData(res)
        setResourcesData([])
        let newData = []
        setResourcesData(newData)
        setLoading(false)
        setFilters([])
        setBookmarksData([])
        postBookmarkIds()
      })
      .catch((err) => console.log(err))
  }, [selectedDisorder])

  useEffect(() => {
    if ((selectedDisorder === disorders[0] && disorders[0] !== 'Depression')) {
      setCurrentResources('recommend')
    } else {
      if (currentResources === 'recommend') {
        setCurrentResources('normal')
      }
    }
  }, [selectedDisorder])

  // const classes = useStyles();
  const [open, setOpen] = React.useState(true)
  const [lang, setLang] = useState(store.get("lang") ? store.get("lang") : "en");
  const [currentResources, setCurrentResources] = React.useState('normal')
  const [dataFinal, setDataFinal] = React.useState({});
  let previousCurrentResources = usePrevious(currentResources);
  const handleClick = () => {
    if (document.getElementById("sidebarMenu").className.includes("show")) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }

  const changeLanguage = () => {
    props.i18n.changeLanguage(lang === "en" ? "fr" : "en")
    setLang(lang === "en" ? "fr" : "en")
    store.set("lang", lang === "en" ? "fr" : "en")
  }

  useEffect(() => {
    if ((selectedDisorder === disorders[0] && disorders[0] !== 'Depression') && currentResources === 'normal' && !renderBookmarks) {
      setCurrentResources('recommend');
    }
    if (previousCurrentResources !== currentResources) {
      setFilters([])
    }
  }, [currentResources, selectedDisorder, renderBookmarks]);

  const finalData = {};
  useEffect(() => {
    if (renderBookmarks) {
      finalData.normal = bookmarksData && bookmarksData.filter(resource => !resource.videoUrl && !resource.recommend);
      finalData.video = bookmarksData && bookmarksData.filter(resource => resource.videoUrl);
      finalData.recommend = bookmarksData && bookmarksData.filter(resource => resource.recommend);
      setDataFinal(finalData);
    } else {
      if (filters && filters.length > 0) {
        finalData.normal = Object.keys(data).filter(key => key !== 'recommended').filter(key => filters.includes(filterMapping[key])).flatMap(key => data[key]).filter(resource => !resource.videoUrl);
        finalData.video = Object.keys(data).filter(key => key !== 'recommended').filter(key => filters.includes(filterMapping[key])).flatMap(key => data[key]).filter(resource => resource.videoUrl);
        finalData.recommend = data['recommended'] ? data['recommended'].filter(res => filters.includes(res.qaulifiers.type.resourceType)) : [] //Object.keys(data).filter(key => key === 'recommended').flatMap(key => data[key]);
        setDataFinal(finalData);
      } else {
        finalData.normal = Object.keys(data).filter(key => key !== 'recommended').flatMap(key => data[key]).filter(data => typeof data == 'object').filter(resource => !resource.videoUrl);
        finalData.video = Object.keys(data).filter(key => key !== 'recommended').flatMap(key => data[key]).filter(resource => resource.videoUrl);
        finalData.recommend = data['recommended'] || [] // Object.keys(data).flatMap(key => data[key]).filter(resource => resource.recommend);
        setDataFinal(finalData);
      }
      // console.log('final data to test', finalData, filters, Object.keys(data))
    }
  }, [renderBookmarks, filters, currentResources, bookmarksIds, loading, bookmarksData])

  const removeFilter = (filter) => {
    setFilters(filters.filter((fltr) => fltr !== filter));
  };

  return (
    <div>
      {props.match.params.assessmentId ? (
        <div className="header--alt">
          <Navbar
            auth={auth}
            params={props.match.params.assessmentId}
            history={props.history}
            changeLanguage={changeLanguage}
            lang={lang}
            disorderState={{ selectedDisorder: [selectedDisorder, setSelectedDisorder] }}
            education_id={props.match.params.assessmentId}
          />
        </div>
      ) : (
        <div className="header--alt">
          <Navbar auth={auth} params="COVID" lang={lang} history={props.history} changeLanguage={changeLanguage} education_id={props.match.params.assessmentId} />
        </div>
      )}
      <div className="row m-0 section-p" style={{ zIndex: 9999 }}>

        <main role="main" className="custom-w col-md-12 ml-sm-auto col-lg-12">
          <div className="title-container">

            <h1 className="main-title">
              {t("education.support")}
            </h1>


            <div className="filters">
              {!renderBookmarks &&
                <div className="flex">
                  <span className="mr-3">{t("education.iLikeToSee")}</span>
                  <div className="dropdown custom-dropdown gradient-bg small-dropdown-btn mr-3">

                    <a className="dropdown-toggle" data-toggle="dropdown">
                      {disorderNames[selectedDisorder] ? disorderNames[selectedDisorder][lang] || selectedDisorder : selectedDisorder}
                      <i className="fas fa-chevron-down"></i>
                    </a>
                    <div className="dropdown-menu custom-menu custom-menu--filtered">
                      {
                        disorders.filter(d =>
                          d.toLowerCase().includes(selectFilter.toLowerCase()) || selectFilter === ''
                        )
                          .map(d => (
                            <a
                              className={`custom-item  ${selectedDisorder === d ? "active" : ""}`}
                              onClick={() => {
                                logClickEvent(`Total # Clicks by ${disorderNames[d] ? disorderNames[d]['en'] || d : d}`, 13, '')
                                setSelectedDisorder(d);
                              }}
                            >
                              {disorderNames[d] ? disorderNames[d][lang] || d : d}
                              <span className="sr-only">(current)</span>
                            </a>
                          ))
                      }
                    </div>
                  </div>
                </div>

              }
              {!renderBookmarks && (currentResources === 'normal') &&
                <div className="dropdown custom-dropdown small-dropdown-btn mr-3">
                  <a className="dropdown-toggle" data-toggle="dropdown">
                    {t("education.filters")}
                    <i className="fas fa-chevron-down"></i>
                  </a>
                  <div className="dropdown-menu custom-menu custom-responsive-menu">
                    {resources.map((name, key) => (
                      <a
                        key={key}
                        style={getStyles(name, personName, theme)}
                        className={`custom-item ${filters.includes(name) ? "active" : ""
                          }`}
                        data-id={name}
                        onClick={() => {
                          if (filters.includes(name)) {
                            setFilters(
                              filters.filter((filter) => filter !== name)
                            )
                          } else {
                            setFilters([...filters, name])
                          }
                          handleChange({ target: { value: name } })
                        }}
                      >
                        {resourcesTranslations[name] ? resourcesTranslations[name][lang] || name : name}
                      </a>
                    ))}
                  </div>
                </div>
              }
              <div className={`custom-dropdown ${renderBookmarks ? "active" : ""
                } `}>
                <button
                  type="button"
                  onClick={() => {
                    setFilters([])
                    setRenderBookmarks(!renderBookmarks)
                    postBookmarkIds()
                  }}
                >
                  {t("education.bookmarks")}
                </button>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-12">
              <ul className="chips-container">
                {filters.map(filter => (
                  filter == 'Video Resources' ? null :
                    <li className="chip" onClick={() => removeFilter(filter)}>
                      {filter}
                      <i className="fa fa-times ml-2"></i>
                    </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <ul className="nav nav-tabs education-tab-part">
                <li className={` nav-item ${currentResources == 'video' ? 'active' : ``}`} onClick={e => { e.preventDefault(); setCurrentResources('video'); setFilters([...filters, 'Video Resources']); }}>
                  <a className={`nav-link custom-tab-link ${currentResources == 'video' ? 'active' : ``}`} onClick={e => { e.preventDefault(); logClickEvent('Total # Clicks in Video Resource Section', 11, ''); setCurrentResources('video'); setFilters([...filters, 'Video Resources']); }}>{t("education.videoResources")}</a>
                </li>
                {((disorders[0] !== selectedDisorder || disorders[0] === 'Depression') || renderBookmarks) &&
                  <li className={` nav-item ${currentResources == 'normal' ? 'active' : ``}`} onClick={e => { e.preventDefault(); setCurrentResources('normal'); setFilters(filters.filter(f => f !== 'Video Resources')) }}>
                    <a class={`nav-link custom-tab-link ${currentResources == 'normal' ? 'active' : ``}`} onClick={e => { e.preventDefault(); logClickEvent('Total # Clicks in Text Resources Section', 11, ''); setCurrentResources('normal'); setFilters(filters.filter(f => f !== 'Video Resources')) }}>{t("education.normalResources")}</a>
                  </li>
                }
                <li className={` nav-item ${currentResources == 'recommend' ? 'active' : ``}`} onClick={e => { e.preventDefault(); setCurrentResources('recommend'); setFilters(filters.filter(f => f !== 'Video Resources')) }}>
                  <a class={`nav-link custom-tab-link ${currentResources == 'recommend' ? 'active' : ``}`} onClick={e => { e.preventDefault(); logClickEvent('Total # Clinks in Recommended Resources Section', 11, ''); setCurrentResources('recommend'); setFilters(filters.filter(f => f !== 'Video Resources')) }}>{t("education.recommendResources")}</a>
                </li>
              </ul>
            </div>
          </div>
          <EducationCardList
            renderBookmarks={renderBookmarks}
            postBookmarkIds={postBookmarkIds}
            selectedDisorder={selectedDisorder}
            bookmarksData={bookmarksData}
            setBookmarks={setBookmarks}
            bookmarksIds={bookmarksIds}
            setBookmarksIds={setBookmarksIds}
            lang={lang}
            t={t}
            filters={filters}
            data={dataFinal[currentResources] || []}
            resourcesData={resourcesData}
            bookmarks={bookmarks}
            loading={loading}
            setBookmarksData={setBookmarksData}
            isVideo={currentResources == 'video'}
            i18n={i18n}
            setLoading={setLoading}
            setBookmarkLoading={setBookmarkLoading}
            bookmarkLoading={bookmarkLoading}
          />
        </main>
      </div>
      <div className="footer">
        <Footer customClassName="custom-w" fromEducation={!!true} />
      </div>
      {/* <FeedbackModel show={showfeedback} handleClose={handleClosefeedback} t={t} from="resources" /> */}
    </div>
  )
}

export default withTranslation()(SupportResources)
